@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;700&display=swap');

body {
  height: 100%;
  margin: 0;
}

:root {
  --main-color: rgb(104, 117, 217);
  --main-color-hover: rgb(104, 117, 217, 0.2);
  --track-color: #ddd;
/* Default mark color*/
  --mark-color: #aaaaaa;
  font-size: 16px;
  font-family: 'Public Sans', sans-serif;
  font-weight: 300;
}

#root {
  height: 100%;
}

.App {
  height: 100%;
}

.customSlider-mark {
  cursor: pointer;
  top: 6px;
  width: 1.5px;
  height: 8px;
  background-color: var(--mark-color);
}

.map-container {
    height: 100vh;
    width: 100vw;
  }

.time-controller {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  text-align: center;
}

.time-controller h1 {
  margin: 0.5rem;
}

.time-controller h3 {
  margin: 0.1rem;
}

.time-controller p {
  margin-top: 0;
}

/* .time-controller > button {
  width: 100px;
} */

.sidebar {
  background-color: rgb(44, 44, 46);
  color: rgb(236, 236, 238);
  width: 400px;
  height: 80%;
  position: fixed;
  right: 20px;
  top: 7vh;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.route-list ul{
  list-style: none;
  margin: 0;
  padding: 0;
}

.route-list li {
  cursor: pointer;
  margin: 0.5rem;
  padding: 1rem;
  background-color: rgb(94, 92, 230);
  color: #FAFAFA;
  border-radius: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.route-list li:hover {
  background-color: #4b4ab8;
}

.route-list {
  overflow: auto;
  height: 75%;
  overflow-y: scroll;
}

.selected-route-wrapper {
  background-color: rgb(48, 209, 88);
  margin: 0.5rem;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.time-controller button {
  background-color: unset;
  color:#FAFAFA;
  border-radius: 4px;
  margin: 0.5rem;
  border: 1px solid #fafafa;
  cursor: pointer;
  padding: 0.75rem 1rem;
}

.time-controller button:hover {
  background-color: rgb(72, 72, 74);
}

/* .time-controller button:disabled {
  color: rgb(58, 58, 60);
} */

.selected-route-wrapper button {
  background: unset;
  border: none;
}

.selected-route-wrapper svg{
  cursor: pointer;
  color: rgb(236, 236, 238);
}

.selected-route-wrapper svg:hover{
  color: rgb(209, 209, 214);
}

.loading-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading-wrapper  h1 {
  background-image: linear-gradient(180deg, rgb(191, 90, 242), rgb(94, 92, 230));
  background-clip: text;
  color: transparent;
  font-size: 6rem;
  margin-top: 0;
}

.loading-wrapper svg{
  color: rgb(191, 90, 242);
}

.sidebar hr {
  width: 100%;
  border-color:rgb(99, 99, 102)
}

.sidebar h4 {
  margin: 0.3rem;
}

.legend {
  background-color: rgb(44, 44, 46);
  color: rgb(236, 236, 238);
  width: 100px;
  position: fixed;
  left: 10px;
  bottom: 7vh;
  padding: 1rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
}

.legend-bus {
  background-color: #f54242;
  width: 20px;
  height: 20px;
  border-radius: 20px;
}

.legend-line {
  border-color: rgba(10, 132, 255, 1);
  border-width: 3px;
  width: 25px;
  margin: 0;
}

.legend > div {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.legend-title {
  font-weight: 800;
  margin-left: 0.5rem;
}

.portfolio-link {
  background-color: rgb(44, 44, 46);
  color: rgb(236, 236, 238);
  /* width: 100px; */
  position: fixed;
  bottom: 0%;
  right: 280px;
  /* padding: 1rem; */
  /* border-radius: 10px; */
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
}

.portfolio-link p{
  margin: 0.2rem;
}

.portfolio-link a {
  color: rgb(236, 236, 238);
}

.portfolio-link a:visited {
  color: rgb(236, 236, 238);
}